body {
  margin: 0;
  font-family: "Comfortaa", sans-serif important!;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.comfortaa-123 {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
